@use "@/styles/variables" as *;

@forward "./styles.base.module.scss";

// The container for the entire component.
.ratingStars {
  // Star SVG
  > .star {
    color: $color-dark;
  }
}
