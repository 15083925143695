@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.borderedSection,
.borderedSectionWithHeading,
.targetDemographicSection {
  padding: 0;
}

.sectionHeading {
  font-size: $font-size-xs;
  font-weight: $font-weight-base;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: $space-xs 0;
}
