@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.topSection {
  flex-direction: column;
  gap: $space-base;
  padding: $space-md $space-lg $space-lg;
  margin-bottom: 0;
  font-size: $font-size-xs;
  border-bottom: 1px solid $color-border;

  .link {
    align-self: flex-start;
  }

  @include md-min {
    font-size: $font-size-sm;
  }
}

.name {
  font-family: $heading-font;
  font-weight: $font-weight-base;
  font-size: $font-size-xl;

  @include md-min {
    font-size: $font-size-xxl;
  }
}

.price {
  font-weight: $font-weight-xthin;
  font-size: $font-size-xl;

  @include md-min {
    font-size: $font-size-xxl;
  }
}

.sectionHeading {
  font-size: $font-size-xs;
  font-weight: $font-weight-base;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.bottomSection {
  padding: 0;
}
