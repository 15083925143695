@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.link {
  text-decoration: none;
}

.variationPanel {
  position: relative;
}

.topSection {
  display: flex;
  justify-content: space-between;
  padding-bottom: $space-xs;
  margin-bottom: $space-xs;
  font-size: $font-size-xs;
  border-bottom: 1px solid $color-border;

  @include md-min {
    font-size: $font-size-sm;
  }
}

.group {
  color: $color-black;
  font-family: $heading-font;
  font-weight: $font-weight-base;
  letter-spacing: $letter-spacing-headline;
}

.nameSection {
  display: flex;
  justify-content: space-between;
}

.name {
  font-family: $heading-font;
  font-size: $font-size-xl;
  letter-spacing: $letter-spacing-headline;
  margin: 0;

  @include md-min {
    font-size: $font-size-xl;
  }
}

.reviewSection {
  display: flex;
  justify-content: space-between;
  padding-top: $space-xxs;

  .link {
    padding-top: $space-xxxxs;
  }
}

.price {
  font-family: $heading-font;
  font-size: $font-size-lg;
  letter-spacing: $letter-spacing-headline;
}

.originalPrice {
  color: $color-price-disabled;
  text-decoration: line-through;
  margin-right: $space-xxxs;
}

.fullDetails {
  bottom: $space-xxs;
  left: 0;
  position: absolute;
  right: 0;
  margin: $space-md 0;
  text-align: center;

  @include md-min {
    bottom: auto;
    top: calc(100% + $space-lg + $space-xxxs);
  }

  > a {
    color: $color-font-base;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    line-height: 1.5;
  }
}
