@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  gap: $space-xs;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.5;
  transition: opacity 0.15s ease-out;
}

.styleButton {
  border-radius: 0;
  position: relative;

  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 95px;
  height: 55px;
  cursor: pointer;

  background-color: transparent;
  border: none;

  &:hover,
  &.hover,
  &.active {
    .image {
      opacity: 1;
    }
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled].hover {
    cursor: auto;

    .image {
      opacity: 0.2;
    }
  }
}

.nameLabel {
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  letter-spacing: 1px;
  color: $color-white;
  display: block;
  padding: 4px 20px;
  position: relative;
  z-index: 1;
}
