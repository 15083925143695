@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.borderedSection,
.borderedSectionWithHeading {
  padding: $space-lg;
  border-bottom: 1px solid $color-border;
}

.borderedSectionWithHeading {
  padding-top: $space-xxxs;
}

.targetDemographicSection {
  padding: $space-lg $space-lg 0;
}
