@use "@/styles/variables" as *;

// `:where()` reduces the specificity of the selector so
// that it can be overridden by the parent component
:where(.buyWithApplePayBtn) {
  --apple-pay-button-border-radius: #{$space-xxxxs};
  --apple-pay-button-height: 36px;
  --apple-pay-button-box-sizing: border-box;

  width: 100%;
}
