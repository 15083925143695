.wrapper {
    display: flow-root;
}

.wait {
    // Show a wait cursor rather than a spinner to prevent layout shift
    cursor: wait;

    // Disable pointer events on children to hide the
    // nested pointer cursor and prevent interaction
    // TODO: Once we no longer need to support Safari <15,
    // we should use the "inert" HTML attribute instead
    > * {
        pointer-events: none;
    }
}
