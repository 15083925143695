@use "sass:math";
@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  --navigation-padding: #{$space-xxs};
  --navigation-button-size: 40px;
  --navigation-container-size: calc((var(--navigation-button-size) * 4) + (var(--navigation-padding) * 2) * 2);
  margin-inline: auto;
  margin-block: $space-md;
  padding-inline: $space-xs;
  border-top: 0.5px solid $color-border;
}

.title {
  font-family: $heading-font;
  font-size: $font-size-md;
  font-weight: $font-weight-light;

  text-align: center;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-headline;

  padding-bottom: $space-md;
  padding-top: $space-md;
}

:is(body) {
  .slider {
    width: calc(100% - var(--navigation-container-size));
  }

  .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
  }

  .navigation {
    @include square;
    margin: unset;
    padding: unset;
    border-radius: 50%;
    border: 1px solid $carousel-navigation;
    color: $carousel-navigation;
    transition: border 1s, color 1s;
    background: $carousel-navigation-background;
    width: var(--navigation-button-size);
    margin-inline: var(--navigation-padding);

    &:hover {
      border: 1px solid $color-black;
      color: $color-black;
    }

    svg {
      transform: scale(0.7);
    }
  }
}